import React from 'react';

import { Tooltip } from 'react-tippy';

import { LoadingBar, SimpleIndicator } from 'components/atoms';

import tooltipStyle from 'constants/tooltipStyle';

import { useOfficeLevel } from 'hooks';

import { getElementByKey, getHumanNumberFormat } from 'utils';

import AcuteHungerIndicator from './AcuteHungerIndicator';
import BreakDownByRegionIndicator from './BreakDownByRegionIndicator';
import BreakDownIndicator from './BreakDownIndicator';
import ChronicHungerIndicator from './ChronicHungerIndicator';
import IDPsBreakDownIndicator from './IDPsBreakDownIndicator';

export const IpcIndicator = ({ data, label }) => {
	const { peak, projected, show_projected: showProjectedState } = data || {};
	const level = useOfficeLevel();
	const showProjected =
		typeof showProjectedState === 'undefined' || showProjectedState === null
			? true
			: showProjectedState;

	const getGlobalValues = () => ({
		peakTotal: getElementByKey(peak?.total, 'value'),
		peakCountries: getElementByKey(peak?.total, 'countries'),
		peakWFP: getElementByKey(peak, 'wfp', {
			countries: null,
			value: null,
		}),
		peakOther: getElementByKey(peak, 'other', {
			countries: null,
			value: null,
		}),
		projectedTotal: getElementByKey(projected?.total, 'value'),
		projectedCountries: getElementByKey(projected?.total, 'countries'),
		projectedWFP: getElementByKey(projected, 'wfp', {
			countries: null,
			value: null,
		}),
		projectedOther: getElementByKey(projected, 'other', {
			countries: null,
			value: null,
		}),
		displayedValue: getElementByKey(peak, 'wfp', {
			countries: null,
			value: null,
		}).value,
	});

	const getRegionValues = () => ({
		peakTotal: getElementByKey(peak, 'value'),
		peakCountries: getElementByKey(peak, 'countries'),
		projectedTotal: getElementByKey(projected, 'value'),
		projectedCountries: getElementByKey(projected, 'countries'),
		displayedValue: getElementByKey(peak, 'value'),
	});

	const getCountryValues = () => ({
		peakTotal: peak,
		projectedTotal: projected,
		displayedValue: peak,
	});

	const getValuesByLevel = {
		country: getCountryValues,
		region: getRegionValues,
		global: getGlobalValues,
	};

	const {
		peakTotal,
		peakCountries,
		peakWFP,
		peakOther,
		projectedTotal,
		projectedCountries,
		projectedWFP,
		projectedOther,
		displayedValue,
		isTooltipDisabled,
	} = getValuesByLevel[level]();

	return (
		<Tooltip
			{...tooltipStyle}
			className="wfp-tooltip wfp-tooltip-v2"
			interactive
			trigger="click"
			position="bottom"
			html={
				<>
					<div className="breakdown-label">{label}</div>

					<div className="breakdown-list">
						<div className="breakdown-list__item-container">
							<div className="breakdown-list__item">
								<div>
									Peak
									{level !== 'country' && (
										<span>({peakCountries} countries)</span>
									)}
								</div>
								<div>{peakTotal ? getHumanNumberFormat(peakTotal) : 'N/A'}</div>
							</div>
						</div>

						{level === 'global' && (
							<div className="extra-data-wrap">
								<div className="extra-data-wrap__inner short">
									<div className="extra-data-wrap__item">
										<div>
											WFP<span>({peakWFP.countries} countries)</span>
										</div>
										<div>
											{peakWFP.value
												? getHumanNumberFormat(peakWFP.value)
												: 'N/A'}
										</div>
									</div>
								</div>
							</div>
						)}
						{showProjected && (
							<div className="breakdown-list__item-container">
								<div className="breakdown-list__item">
									<div>
										Projection
										{level !== 'country' && (
											<span>({projectedCountries} countries)</span>
										)}
									</div>
									<div>
										{projectedTotal
											? getHumanNumberFormat(projectedTotal)
											: 'N/A'}
									</div>
								</div>
								{level === 'global' && (
									<div className="extra-data-wrap">
										<div className="extra-data-wrap__inner">
											<div className="extra-data-wrap__item">
												<div>
													WFP<span>({projectedWFP.countries} countries)</span>
												</div>
												<div>
													{projectedWFP.value
														? getHumanNumberFormat(projectedWFP.value)
														: 'N/A'}
												</div>
											</div>
											<div className="extra-data-wrap__item">
												<div>
													Other
													<span>({projectedOther.countries} countries)</span>
												</div>
												<div>
													{projectedOther.value
														? getHumanNumberFormat(projectedOther.value)
														: 'N/A'}
												</div>
											</div>
										</div>
									</div>
								)}
							</div>
						)}
					</div>
				</>
			}
		>
			<div className="population-item-row">
				<span className="population-item-row__label">{label}</span>
				<span className="population-item-row__value breakdown">
					{displayedValue ? getHumanNumberFormat(displayedValue) : 'N/A'}
				</span>
			</div>
		</Tooltip>
	);
};

const PopulationHeaderPanel = ({ data, loading, level = 'global' }) => {
	const {
		forced_to_flee: forcedToFlee = {},
		malnourished,
		wasting,
		stunting,
		population,
		ipc_phase: ipcPhase,
		ipc_phase_4_5: ipcPhase45,
		ipc_phase_5: ipcPhase5,
		grouped_indicators: regionIndicators = [],
		acute_hunger_gorp: acuteHungerGORP,
		acute_hunger_countries: acuteHungerCountries,
	} = data || {};

	const malnourishedHuman = getHumanNumberFormat(malnourished);
	const refugees =
		forcedToFlee && forcedToFlee.refugees ? forcedToFlee.refugees.total : null;
	const idp =
		forcedToFlee && forcedToFlee.internally_displaced
			? forcedToFlee.internally_displaced
			: null;

	return (
		<div className="header-dashboard population-dashboard">
			<div className="header-dashboard__indicators">
				{loading ? (
					<div className="loading-container">
						<LoadingBar isSmall={false} />
					</div>
				) : (
					<>
						<div className="title__container">
							<h3 className="column__title">
								{level[0].toUpperCase()}
								{level.slice(1)} population
							</h3>
							{level === 'country' && (
								<p className="column__subtitle">
									{population
										? `${getHumanNumberFormat(population)} people`
										: 'N/A'}
								</p>
							)}
						</div>
						<div className="population-items">
							{level !== 'global' ? (
								<>
									<div className="header-dashboard-item population-item">
										<div className="population-item__block">
											{level !== 'country' && (
												<h4 className="header-dashboard-item__label">Hunger</h4>
											)}
											<SimpleIndicator
												label="Chronic Hunger (SOFI)"
												data={malnourished ? malnourishedHuman : 'N/A'}
											/>
											{level === 'region' ? (
												<AcuteHungerIndicator
													value={acuteHungerGORP}
													countries={acuteHungerCountries}
												/>
											) : null}
										</div>

										<div className="population-item__block">
											<h4 className="header-dashboard-item__label">
												IPC/CH and equivalent
											</h4>
											<IpcIndicator
												label="Phase 3, 4 and 5"
												data={ipcPhase || 'N/A'}
											/>
											<IpcIndicator
												label="Phase 4 and 5"
												data={ipcPhase45 || 'N/A'}
											/>
											<IpcIndicator label="Phase 5" data={ipcPhase5 || 'N/A'} />
										</div>

										<div className="population-item__block">
											<h4 className="header-dashboard-item__label">
												Nutrition
											</h4>
											<SimpleIndicator
												label="Children wasting"
												data={wasting || 'N/A'}
											/>
											<SimpleIndicator
												label="Children stunting"
												data={stunting || 'N/A'}
											/>
										</div>
									</div>

									<div className="header-dashboard-item population-item">
										<h4 className="header-dashboard-item__label">
											<SimpleIndicator
												label="Forcibly displaced"
												data={
													forcedToFlee.total === null
														? 'N/A'
														: forcedToFlee.total
												}
											/>
										</h4>
										{forcedToFlee.total ? (
											<>
												<SimpleIndicator
													label="Refugees"
													data={refugees === null ? 'N/A' : refugees}
												/>
												<IDPsBreakDownIndicator label="Total IDPs" data={idp} />
											</>
										) : null}
									</div>
								</>
							) : (
								<>
									<div className="header-dashboard-item population-item global">
										<div className="population-item__block">
											<h4 className="header-dashboard-item__label">Hunger</h4>
											<ChronicHungerIndicator data={malnourished} />
											<AcuteHungerIndicator
												value={acuteHungerGORP}
												countries={acuteHungerCountries}
											/>
										</div>

										<div className="population-item__block">
											<h4 className="header-dashboard-item__label">
												IPC/CH and equivalent
											</h4>
											<IpcIndicator label="Phase 3, 4 and 5" data={ipcPhase} />
											<IpcIndicator label="Phase 4 and 5" data={ipcPhase45} />
											<IpcIndicator label="Phase 5" data={ipcPhase5} />
										</div>

										<div className="population-item__block">
											<h4 className="header-dashboard-item__label">
												Nutrition
											</h4>
											<BreakDownIndicator
												label="Children wasting"
												data={wasting}
											/>
											<BreakDownIndicator
												label="Children stunting"
												data={stunting}
											/>
										</div>
									</div>

									<div className="header-dashboard-item population-item">
										<BreakDownByRegionIndicator
											indicators={regionIndicators}
											forcedToFlee={forcedToFlee}
										/>
									</div>
								</>
							)}
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default PopulationHeaderPanel;
